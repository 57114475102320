import React, { Component } from 'react'
import './PolygonOverlay.css'
export default class PolygonOverlay extends Component {

    render() {
        return (
            <div className='rootPolygonOverlay'>
            </div>
        )
    }
}