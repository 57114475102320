import React, { Component } from 'react'
import './PolygonOverlay.css'
export default class PolygonOverlayInverted extends Component {

    render() {
        return (
            <div className='rootPolygonOverlayInverted'>
            </div>
        )
    }
}